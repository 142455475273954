import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import reset from 'styled-reset';
import media from '../common/MediaQueries';
import '../assets/fonts/fonts.css';

const GlobalStyles = createGlobalStyle`
      ${reset}
      ${normalize}
      * {
        box-sizing: border-box;
      }
      .gatsby-image-wrapper {
        height: 100%;
      }
      body {
        margin: 0;
        width: 100%;
        background-color: ${({ theme }) => theme.colors.neutral.neutral10};
        color: ${({ theme }) => theme.colors.additional.dark};
        font-size: 16px;
        line-height: 1.3;
        font-family: 'TT Firs Neue', sans-serif;
        font-weight: 400;
      }
      button {
        color: inherit; // apple devices fix
        cursor: pointer;
      }
      a {
        display: inline-block;
        color: inherit;
        text-decoration: none;
        background-color: transparent;
      }
      svg {
        &:not([class]) {}
      }
      strong {
        font-weight: 500;
      }
      body.has-overflow-hidden {
        @media ${media.maxTablet} {
          overflow: hidden;
        }
      }
      body.has-gutter-bottom {
        @media ${media.maxTablet} {
          padding-bottom: 5.75rem;
        }
      }
    `;

export default GlobalStyles;
